<template>
  <div class="custom-wrapper">
    <DxResponsiveBox :screen-by-width="screenByWidth" single-column-screen="xs" id="responsive-box" width="100%">
      <DxRow :ratio="1" />
      <DxRow :ratio="1" />
      <DxRow :ratio="1" />
      <DxRow :ratio="1" />
      <DxRow :ratio="1" />
      <DxRow :ratio="1" />
      <DxRow :ratio="1" />
<!--      <DxRow :ratio="1" />-->

      <DxCol :ratio="1" />
      <DxCol :ratio="1" />
      <DxCol :ratio="1" />
      <DxCol :ratio="1" />
      <DxCol :ratio="1" />
      <DxCol :ratio="1" />

      <!--Filters-->
      <DxItem class="category-list-selector">
        <DxLocation :row="0" :col="0" :colspan="6" screen="xs sm md lg" />
        <template #default >
          <div class="filterContainer">

            <!-- # STORE FILTER -->
            <div class="storeSelectorContainer">
              <!--                          temp-->
              <DxSelectBox style="font-family: 'Nunito'"
                           :dataSource="getOutletsList.filter(item => item.id != 0)"
                           displayExpr= "name"
                           :show-clear-button="false"
                           :placeholder="$store.state.selectedOutlet.name"
                           value-expr="id"
                           :onValueChanged="onOutletSelected"
                           :disabled="!pageDataReady"
                           styling-mode="underlined"
              />
              <!--                     v-model="selectedOutlet"-->

            </div>

            <div class="filtersContainer">

              <div>
                <!--Calendar-->
                <div class="display-calendar-btn" @click="onShowCalendar">{{ $store.state.selectedDate ? (new Date($store.state.selectedDate.slice(0,4), ($store.state.selectedDate.slice(4,6) - 1), $store.state.selectedDate.slice(6,8))).toLocaleString('en-us', { month: 'short' }) + " " + $store.state.selectedDate.slice(0,4) : (new Date(String($store.state.newOutletLastSalesDates.lastAvailableSalesDate).slice(0,4), (String($store.state.newOutletLastSalesDates.lastAvailableSalesDate).slice(4,6)), String($store.state.newOutletLastSalesDates.lastAvailableSalesDate).slice(6,8))).toLocaleString('en-us', { month: 'short' }) + " " + String($store.state.newOutletLastSalesDates.lastAvailableSalesDate).slice(0,4) }}</div>
                <div :class="['calendarContainer', calendarHidden ? 'calendarHidden' : '']">
                <div class="closeCalendarBtn" @click="onShowCalendar">x</div>
                <div class="calendarButton">
                  <div class="calendarButtonTitle"></div>

                  <button v-for="(dateBtn, ix) in [{name: 'month', value: 'year'}, {name: 'week', value: 'month'}]" :key="ix"
                          :class="[(dateBtn.name + '-btn'), calendarZoomLevel === dateBtn.value ? 'activeDate' : '', dateBtn.value === 'month' ? 'disabled-btn' : '']"
                          @click="onCalendarZoomLevelSelection(dateBtn)"
                          :disabled="dateBtn.value === 'month'"
                  >{{ dateBtn.name }}</button>
                </div>
                <div class="calendarComponentContainer">
                  <div class="calendarTitle">{{ LOC.FILTERS.CALENDARREFTITLE }}</div>
                  <DxCalendar
                      @value-changed="onSelectedDate"
                      :zoom-level="calendarZoomLevel"
                      :max-zoom-level="calendarZoomLevel"
                      :max="maxSelectableDate"
                      :min="minSelectableDate"
                      :value="$store.state.selectedDate ? `${$store.state.selectedDate.slice(0,4)}-${$store.state.selectedDate.slice(4,6)}-01` : `${('' + $store.state.newOutletLastSalesDates.lastAvailableSalesDate).slice(0,4)}-${('' + $store.state.newOutletLastSalesDates.lastAvailableSalesDate).slice(4,6)}-01`"
                      cell-template="customCalendarCellTemplate"
                  >
                    <template #customCalendarCellTemplate="{ data: data, index }">
                      <CustomCalendarCellTemplate :data="data" :cell-index="index" />
                    </template>
                  </DxCalendar>
                </div>
              </div>

                <!--Settings-->
                <div class="display-settings-btn" @click="onShowSettings"><i class="dx-icon dx-icon-preferences fa-4x"></i></div>
                <div :class="['calendarContainer', settingsHidden ? 'calendarHidden' : '']">
                  <div class="closeCalendarBtn" @click="onShowSettings">x</div>
                  <div class="calendarButton">
                    <div class="calendarButtonTitle"></div>
                    <button v-for="(dateBtn, ix) in [{name: 'year', value: 'year'}]" :key="ix"
                            :class="[(dateBtn.name + '-btn'), calendarZoomLevel === dateBtn.value ? 'activeDate' : '', dateBtn.value === 'month' ? 'disabled-btn' : '']"
                            @click="onCalendarZoomLevelSelection(dateBtn)"
                            :disabled="dateBtn.value === 'year'"
                    >
                      {{ dateBtn.name }}
                    </button>
                  </div>
                  <div class="calendarComponentContainer">
                    <div class="calendarTitle">{{ LOC.FILTERS.CALENDARCOMPARISONTITLE }}</div>
                    <DxCalendar
                        @value-changed="onSelectedSetting"
                        :zoom-level="settingsZoomLevel"
                        :max-zoom-level="settingsZoomLevel"
                        :min-zoom-level="settingsZoomLevel"
                        :max="settingsMaxSelectableDate"
                        :min="settingsMinSelectableDate"
                        :value="$store.state.selectedSettings ? `${$store.state.selectedSettings.slice(0,4)}` : `${ '' + (('' + $store.state.newOutletLastSalesDates.lastAvailableSalesDate).slice(0,4)-1) }`"
                    >
                    </DxCalendar>
                  </div>
                </div>
              </div>

              <!-- # PRODUCT CATEGORIES FILTER -->
              <div class="categoriesListSelectorContainer" style="width: 350px; font-family: 'Nunito'">
                <DxSelectBox style="font-family: 'Nunito'"
                             :dataSource="getCategoriesList"
                             displayExpr= "description"
                             :placeholder="LOC.FILTERS.PRODCATPLACEHOLDER"
                             value-expr="id"
                             :onValueChanged="onProductCategorySelected"
                             :disabled="!pageDataReady || productCategoryFilterDisabledByPage"
                             :show-clear-button="true"
                             styling-mode="underlined"
                             :value="$store.state.selectedCategory"

                />
                <!--                       v-model="selectedCategory"-->
                <!--                    <DxTextBoxButton name="clear" :options="{ }"/>-->
              </div>

            </div>
          </div>
        </template>
      </DxItem>

      <!-- 1st row -->

<!--      #alimentato da salesSnapshot-->
      <DxItem>
        <DxLocation :row="1" :col="0" :colspan="3" screen="xs sm md lg" />
        <template #default>
          <basic-text-visualizer
              :componentTitle="LOC.NETSALES.NETSALES"
              :comparisonDate="comparisonDateText"
              :comparisonValue="Math.round(cloumnStatistic.netSales.salesREFPY)"
              :refDate="refDateText"
              :refValue="Math.round(cloumnStatistic.netSales.salesREF)"
              :trendValue="cloumnStatistic.netSales.varSales"
              :isCurrency="true"
          />
        </template>
      </DxItem>

      <!--      #alimentato da salesTotalsYTD-->
      <DxItem>
        <DxLocation :row="1" :col="3" :colspan="3" screen="xs sm md lg" />
        <template #default>
          <basic-text-visualizer
              :componentTitle="LOC.NETSALES.NETSALESYTD"
              :comparisonDate="comparisonDateTextYTD"
              :comparisonValue="Math.round(cloumnStatistic.netSalesYTD.salesPY)"
              :refDate="refDateTextYTD"
              :refValue="Math.round(cloumnStatistic.netSalesYTD.sales)"
              :trendValue="cloumnStatistic.netSalesYTD.varSales"
              :isCurrency="true"
          />
        </template>
      </DxItem>

      <!-- 2nd row -->
      <!--      #alimentato da salesSnapshot-->
      <DxItem>
        <DxLocation :row="2" :col="0" :colspan="3" screen="xs sm md lg" />
        <template #default>
          <basic-text-visualizer
              :componentTitle="LOC.NETSALES.NETSALESSQM"
              :comparisonDate="comparisonDateText"
              :comparisonValue="cloumnStatistic.netSalesSqm.salesSqmREFPY"
              :refDate="refDateText"
              :refValue="cloumnStatistic.netSalesSqm.salesSqmREF"
              :trendValue="cloumnStatistic.netSalesSqm.varSalesSqm"
              :isCurrency="true"
          />
        </template>
      </DxItem>

      <!--      #alimentato da salesTotalsYTD-->
      <DxItem>
        <DxLocation :row="2" :col="3" :colspan="3" screen="xs sm md lg" />
        <template #default>
          <basic-text-visualizer
              :componentTitle="LOC.NETSALES.NETSALESSQMYTD"
              :comparisonDate="comparisonDateTextYTD"
              :comparisonValue="cloumnStatistic.netSalesSqmYTD.salesSqmPY"
              :refDate="refDateTextYTD"
              :refValue="cloumnStatistic.netSalesSqmYTD.salesSqm"
              :trendValue="cloumnStatistic.netSalesSqmYTD.varSalesSqm"
              :isCurrency="true"
          />
        </template>
      </DxItem>

      <!-- 3rd row -->
      <DxItem>
        <DxLocation :row="3" :col="0" :colspan="3" screen="xs sm md lg" />
        <template #default>
          <basic-text-visualizer
              :componentTitle="LOC.NETSALES.AVGTICKET"
              :comparisonDate="comparisonDateText"
              :comparisonValue="cloumnStatistic.avegTicket.avgTicketREFPY"
              :refDate="refDateText"
              :refValue="cloumnStatistic.avegTicket.avgTicketREF"
              :trendValue="cloumnStatistic.avegTicket.varAvgTicket"
              :isCurrency="true"
          />
        </template>
      </DxItem>

      <DxItem>
        <DxLocation :row="3" :col="3" :colspan="3" screen="xs sm md lg" />
        <template #default>
          <basic-text-visualizer
              :componentTitle="LOC.NETSALES.SPENDPERVISITOR"
              :comparisonDate="comparisonDateText"
              :comparisonValue="cloumnStatistic.spendPerVisitor.spvREFPY"
              :refDate="refDateText"
              :refValue="cloumnStatistic.spendPerVisitor.spvREF"
              :trendValue="cloumnStatistic.spendPerVisitor.varSPV"
              :isCurrency="true"
          />
        </template>
      </DxItem>

      <!-- 4th row -->
      <DxItem>
        <DxLocation :row="4" :col="0" :colspan="3" screen="xs sm md lg" />
        <template #default>
          <basic-text-visualizer
              :componentTitle="LOC.NETSALES.OPENDAYS"
              :comparisonDate="comparisonDateText"
              :comparisonValue="cloumnStatistic.openDays.openDaysREFPY"
              :refDate="refDateText"
              :refValue="cloumnStatistic.openDays.openDaysREF"
              :emptyTrendValue="true"
          />
        </template>
      </DxItem>

      <DxItem>
        <DxLocation :row="4" :col="3" :colspan="3" screen="xs sm md lg" />
        <template #default>
          <basic-text-visualizer
              :componentTitle="LOC.NETSALES.CRRETAIL"
              :comparisonDate="comparisonDateText"
              :comparisonValue="cloumnStatistic.crRetail.crREFPY"
              :refDate="refDateText"
              :refValue="cloumnStatistic.crRetail.crREF"
              :trendValue="cloumnStatistic.crRetail.varCR"
              :isPercentage="true"
              :is-delta-number="true"
          />
        </template>
      </DxItem>

      <!-- 5th row -->
      <DxItem>
        <DxLocation :row="5" :col="0" :colspan="6" screen="xs sm md lg" />
        <template #default>
          <div style="position: relative;">

            <DxButton
                :style="`display: ${dataDisplayMode1 == 'chart' ? 'block' : 'none'}`"
                id="reset-zoom" :on-click="resetScatterErZoom1" :text="LOC.COMMON.RESETZOOMTEXT" class="reset-zoom-btn"/>

            <basic-chart-datagrid-visualizer :componentTitle="LOC.NETSALES.NETSALESNETSALESSQMLTM" :refDate="refDateTextLTMComparisonDateTextLTM" :dataReady="pageDataReady" :switch-table-btn="LOC.NETSALES.CHARTDATAGRIDSWITCHTABLE" :switch-chart-btn="LOC.NETSALES.CHARTDATAGRIDSWITCHCHART" @changeDisplayDataChild="changeDisplayData1($event)">

              <template v-slot:componentChart>
                <DxChart @drawn="onDrawn"
                         id="scatterChart-filtered-by-client"
                         class="linesPanes"
                         :data-source="scatterDatagridERLTM.dataFilteredByProductCategory"
                         :commonAxisSettings="scatterChartCommonAxisSettings"
                         :loadingIndicator="{enabled: true}"
                         @initialized="saveScatterErInstance"
                         default-pane="bottomPane"
                >
                  <DxZoomAndPan :drag-to-zoom="true" pan-key="ctrl" argument-axis="both" value-axis="both" :allow-touch-gestures="true"/>

                  <DxCommonSeriesSettings type="line"></DxCommonSeriesSettings>

                  <DxSeries pane="topPane" argument-field="periodOrdinal" value-field="salesSqm" :color="getOutletStyle.primaryColor" :name="refDateTextLTM" :showInLegend="false">
                    <DxPoint :color="getOutletStyle.primaryColor" :size="scatterERLTM.points1.size" :visible="true" />
                  </DxSeries>

                  <DxSeries pane="topPane" argument-field="periodOrdinal" value-field="salesSqmPY" :color="getOutletStyle.secondaryColor" :name="comparisonDateTextLTM" :showInLegend="false">
                    <DxPoint :color="getOutletStyle.secondaryColor" :size="scatterERLTM.points2.size" :visible="true" />
                  </DxSeries>


                  <DxSeries argument-field="periodOrdinal" value-field="salesPY" :color="getOutletStyle.secondaryColor" :name="comparisonDateTextLTM">
                    <DxPoint :color="getOutletStyle.secondaryColor" :size="scatterERLTM.points2.size" :visible="true" />
                  </DxSeries>

                  <DxSeries argument-field="periodOrdinal" value-field="sales" :color="getOutletStyle.primaryColor" :name="refDateTextLTM">
                    <DxPoint :color="getOutletStyle.primaryColor" :size="scatterERLTM.points1.size" :visible="true" />
                  </DxSeries>

                  <DxTooltip :enabled="true" :content-template="getTooltipTemplate" :argument-format="scatterERLTM.tooltipCr.argumentFormat" :format="scatterERLTM.tooltipCr.valueFormat" />

                  <DxPane name="topPane" height="auto"/>
                  <DxPane name="bottomPane" height="auto"/>

                  <DxArgumentAxis pane="topPane" :title="LOC.NETSALES.LINECHARTARGAXISTITLE" :label="{ customizeText: newArgAxisValue }">
                    <DxGrid :visible="false" />
                    <DxMinorGrid :visible="false" />
                  </DxArgumentAxis>

                  <DxArgumentAxis pane="bottomPane" :title="LOC.NETSALES.LINECHARTARGAXISTITLE" :label="{ customizeText: newArgAxisValue }">
                    <DxGrid :visible="false" />
                    <DxMinorGrid :visible="false" />
                  </DxArgumentAxis>

                  <DxValueAxis pane="topPane" :grid="{ visible: true }" :title="LOC.NETSALES.NETSALESSQMEUR" :visible="false"/>
                  <DxValueAxis :grid="{ visible: true }" :title="LOC.NETSALES.NETSALESEUR" :visible="false"/>

                  <DxLegend :visible="true" vertical-alignment="bottom" horizontal-alignment="center" :margin="{ top: 25 }" itemTextPosition="right" :font="getLegendFontSettings"/>

                  <DxCommonPaneSettings>
                    <DxBorder :visible="false" />
                  </DxCommonPaneSettings>

                  <template #tooltipTemplateNetSaleSqm="{ data }">
                    <div style="text-align: center">
                      Net Sales/sqm: € {{data.valueText.toLocaleString("it-IT")}}<br>
                      Month: {{ (data.point.series.getValueFields() == "salesSqm") ? data.point.data.periodPlainAlt : data.point.data.periodPlainAltPY}}
                    </div>
                  </template>

                  <template #tooltipTemplateNetSale="{ data }">
                    <div style="text-align: center">
                      Net Sales: € {{Math.round(parseFloat(data.valueText)).toLocaleString("it-IT")}}<br>
                      Month: {{ (data.point.series.getValueFields() == "sales") ? data.point.data.periodPlainAlt : data.point.data.periodPlainAltPY}}
                    </div>
                  </template>

                </DxChart>
              </template>

              <template v-slot:componentTab>
                <DxDataGrid
                    id="dataGrid-filtered-by-client2"
                    class="sales"
                    :data-source="scatterDatagridERLTM.dataFilteredByProductCategory"
                    key-expr="periodOrdinal"
                    :show-borders="false"
                    :loadPanel="{enabled: true}"
                    @exporting="onExportingDatagridEr"
                    :show-column-lines="false"
                    :show-row-lines="true"
                    :column-hiding-enabled="true"
                    :column-auto-width="true"
                    :noDataText="LOC.COMMON.NODATA"
                >
                  <DxPaging :enabled="false" />
                  <DxExport
                      :enabled="true"
                  />

                  <DxColumn
                      css-class="cell-custom-style cell-body-head"
                      data-field="periodOrdinal"
                      :caption="LOC.NETSALES.DATAGRIDVISITORSCOL1CAPTION"
                      alignment="left"
                      cell-template="ordinal-to-month"
                      sort-order="asc"
                  />

                  <DxColumn
                      css-class="cell-custom-style"
                      data-field="sales"
                      :caption="LOC.NETSALES.DATAGRIDVISITORSCOL2CAPTION"
                      data-type="number"
                      alignment="left"
                  >
                    <DxFormat type="fixedPoint" :precision="0"/>
                  </DxColumn>

                  <DxColumn
                      css-class="cell-custom-style"
                      data-field="salesPY"
                      :caption="LOC.NETSALES.DATAGRIDVISITORSCOL3CAPTION"
                      data-type="number"
                      alignment="left"
                  >
                    <DxFormat type="fixedPoint" :precision="0"/>
                  </DxColumn>

                  <DxColumn
                      css-class="cell-custom-style"
                      data-field="varSales"
                      :caption="LOC.NETSALES.DATAGRIDVISITORSCOL4CAPTION"
                      data-type="number"
                      alignment="left"
                      cell-template="posNegCellTemplateSales"
                  >
                    <DxFormat type="fixedPoint" :precision="2"/>
                  </DxColumn>

                  <DxColumn
                      css-class="cell-custom-style"
                      data-field="salesSqm"
                      :caption="LOC.NETSALES.DATAGRIDVISITORSCOL5CAPTION"
                      data-type="number"
                      alignment="left"
                  >
                    <DxFormat type="fixedPoint" :precision="2"/>
                  </DxColumn>

                  <DxColumn
                      css-class="cell-custom-style"
                      data-field="salesSqmPY"
                      :caption="LOC.NETSALES.DATAGRIDVISITORSCOL6CAPTION"
                      data-type="number"
                      alignment="left"
                  >
                    <DxFormat type="fixedPoint" :precision="2"/>
                  </DxColumn>

                  <DxColumn
                      css-class="cell-custom-style"
                      data-field="varSalesSqm"
                      :caption="LOC.NETSALES.DATAGRIDVISITORSCOL7CAPTION"
                      data-type="number"
                      alignment="left"
                      cell-template="posNegCellTemplateSales"
                  >
                    <DxFormat type="fixedPoint" :precision="2"/>
                  </DxColumn>

                  <template #posNegCellTemplateSales="{ data }">
                    <div :class="data.value != null ? data.value < 0 ? 'negative-value' : '' : ''">{{ data.text}}</div>
                  </template>

                  <template #ordinal-to-month="{ data }">
                    <div>{{ fromOrdinalToMonth(data.value) }}</div>
                  </template>

                </DxDataGrid>
              </template>

            </basic-chart-datagrid-visualizer>
          </div>
        </template>
      </DxItem>

      <!-- 6th row -->
      <DxItem>
        <DxLocation :row="6" :col="0" :colspan="6" screen="xs sm md lg" />
        <template #default>
          <div style="position: relative;">

            <DxButton id="reset-zoom2" :on-click="resetScatterErZoom2" :text="LOC.COMMON.RESETZOOMTEXT" class="reset-zoom-btn" :style="`display: ${dataDisplayMode2 == 'chart' ? 'block' : 'none'}`"/>

            <basic-chart-datagrid-visualizer :componentTitle="LOC.NETSALES.AVGTICKETSPENDPERVISITORLTM" :refDate="refDateTextLTMComparisonDateTextLTM" :dataReady="pageDataReady" :switch-table-btn="LOC.NETSALES.CHARTDATAGRIDSWITCHTABLE" :switch-chart-btn="LOC.NETSALES.CHARTDATAGRIDSWITCHCHART" @changeDisplayDataChild="changeDisplayData2($event)">

              <template v-slot:componentChart>
                <DxChart @drawn="onDrawn"
                         class="linesPanes"
                         id="scatterChart-filtered-by-client2"
                         :data-source="scatterDatagridERLTM.dataFilteredByProductCategory"
                         :commonAxisSettings="scatterChartCommonAxisSettings"
                         :loadingIndicator="{enabled: true}"
                         @initialized="saveScatterErInstance2"
                         default-pane="bottomPane"
                >
                  <DxZoomAndPan :drag-to-zoom="true" pan-key="ctrl" argument-axis="both" value-axis="both" :allow-touch-gestures="true"/>

                  <DxCommonSeriesSettings type="line"></DxCommonSeriesSettings>

                  <DxSeries pane="topPane" argument-field="periodOrdinal" value-field="spv" :color="getOutletStyle.primaryColor" :name="refDateTextLTM" :showInLegend="false">
                    <DxPoint :color="getOutletStyle.primaryColor" :size="scatterERLTM.points1.size" :visible="true" />
                  </DxSeries>

                  <DxSeries pane="topPane" argument-field="periodOrdinal" value-field="spvPY" :color="getOutletStyle.secondaryColor" :name="comparisonDateTextLTM" :showInLegend="false">
                    <DxPoint :color="getOutletStyle.secondaryColor" :size="scatterERLTM.points2.size" :visible="true" />
                  </DxSeries>

                  <DxSeries argument-field="periodOrdinal" value-field="avgTicketPY" :color="getOutletStyle.secondaryColor" :name="comparisonDateTextLTM">
                    <DxPoint :color="getOutletStyle.secondaryColor" :size="scatterERLTM.points2.size" :visible="true" />
                  </DxSeries>

                  <DxSeries argument-field="periodOrdinal" value-field="avgTicket" :color="getOutletStyle.primaryColor" :name="refDateTextLTM">
                    <DxPoint :color="getOutletStyle.primaryColor" :size="scatterERLTM.points1.size" :visible="true" />
                  </DxSeries>

                  <DxPane name="topPane" height="auto"/>
                  <DxPane name="bottomPane" height="auto"/>

                  <DxTooltip :enabled="true" :content-template="getTooltipTemplate2" :argument-format="scatterERLTM.tooltipCr.argumentFormat" :format="scatterERLTM.tooltipCr.valueFormat" />

                  <DxArgumentAxis pane="topPane" :title="LOC.NETSALES.LINECHARTARGAXISTITLE" :label="{ customizeText: newArgAxisValue }">
                    <DxGrid :visible="false" />
                    <DxMinorGrid :visible="false" />
                  </DxArgumentAxis>

                  <DxArgumentAxis pane="bottomPane" :title="LOC.NETSALES.LINECHARTARGAXISTITLE" :label="{ customizeText: newArgAxisValue }">
                    <DxGrid :visible="false" />
                    <DxMinorGrid :visible="false" />
                  </DxArgumentAxis>

                  <DxValueAxis pane="topPane" :grid="{ visible: true }" :title="LOC.NETSALES.SPENDPERVISITOREUR" :visible="false"/>
                  <DxValueAxis :grid="{ visible: true }" :title="LOC.NETSALES.AVGTICKETEUR" :visible="false"/>

                  <DxLegend :visible="true" vertical-alignment="bottom" horizontal-alignment="center" :margin="{ top: 25 }" itemTextPosition="right" :font="getLegendFontSettings"/>

                  <DxCommonPaneSettings>
                    <DxBorder :visible="false" />
                  </DxCommonPaneSettings>

                  <template #tooltipTemplateAVG="{ data }">
                    <div style="text-align: center">
                      AVG Ticket: € {{ data.valueText.toLocaleString("it-IT") }}<br>
                      Month: {{ (data.point.series.getValueFields() == "avgTicket") ? data.point.data.periodPlainAlt : data.point.data.periodPlainAltPY}}
                    </div>
                  </template>

                  <template #tooltipTemplateSPV="{ data }">
                    <div style="text-align: center">
                      Spend Per Visitor: € {{ data.valueText.toLocaleString("it-IT") }}<br>
                      Month: {{ (data.point.series.getValueFields() == "spv") ? data.point.data.periodPlainAlt : data.point.data.periodPlainAltPY}}
                    </div>
                  </template>

                </DxChart>
              </template>

              <template v-slot:componentTab>
                <DxDataGrid
                    id="dataGrid-filtered-by-client"
                    class="sales"
                    :data-source="scatterDatagridERLTM.dataFilteredByProductCategory"
                    key-expr="periodOrdinal"
                    :show-borders="false"
                    :loadPanel="{enabled: true}"
                    @exporting="onExportingDatagridEr"
                    :show-column-lines="false"
                    :show-row-lines="true"
                    :column-hiding-enabled="true"
                    :column-auto-width="true"
                    :noDataText="LOC.COMMON.NODATA"
                >
                  <DxPaging :enabled="false" />
                  <DxExport
                      :enabled="true"
                  />

                  <DxColumn
                      css-class="cell-custom-style cell-body-head"
                      data-field="periodOrdinal"
                      :caption="LOC.NETSALES.DATAGRIDVISITORSCOL8CAPTION"
                      alignment="left"
                      cell-template="ordinal-to-month"
                      sort-order="asc"
                  />

                  <DxColumn
                      css-class="cell-custom-style"
                      data-field="avgTicket"
                      :caption="LOC.NETSALES.DATAGRIDVISITORSCOL9CAPTION"
                      data-type="number"
                      alignment="left"
                  >
                    <DxFormat type="fixedPoint" :precision="2"/>
                  </DxColumn>

                  <DxColumn
                      css-class="cell-custom-style"
                      data-field="avgTicketPY"
                      :caption="LOC.NETSALES.DATAGRIDVISITORSCOL10CAPTION"
                      data-type="number"
                      alignment="left"
                  >
                    <DxFormat type="fixedPoint" :precision="2"/>
                  </DxColumn>

                  <DxColumn
                      css-class="cell-custom-style"
                      data-field="varAvgTicket"
                      :caption="LOC.NETSALES.DATAGRIDVISITORSCOL11CAPTION"
                      data-type="number"
                      alignment="left"
                      cell-template="posNegCellTemplateSales"
                  >
                    <DxFormat type="fixedPoint" :precision="2"/>
                  </DxColumn>

                  <DxColumn
                      css-class="cell-custom-style"
                      data-field="spv"
                      :caption="LOC.NETSALES.DATAGRIDVISITORSCOL12CAPTION"
                      data-type="number"
                      alignment="left"
                  >
                    <DxFormat type="fixedPoint" :precision="2"/>
                  </DxColumn>

                  <DxColumn
                      css-class="cell-custom-style"
                      data-field="spvPY"
                      :caption="LOC.NETSALES.DATAGRIDVISITORSCOL13CAPTION"
                      data-type="number"
                      alignment="left"
                  >
                    <DxFormat type="fixedPoint" :precision="2"/>
                  </DxColumn>

                  <DxColumn
                      css-class="cell-custom-style"
                      data-field="varSPV"
                      :caption="LOC.NETSALES.DATAGRIDVISITORSCOL14CAPTION"
                      data-type="number"
                      alignment="left"
                      cell-template="posNegCellTemplateSales"
                  >
                    <DxFormat type="fixedPoint" :precision="2"/>
                  </DxColumn>

                  <template #posNegCellTemplateSales="{ data }">
                    <div :class="data.value != null ? data.value < 0 ? 'negative-value' : '' : ''">{{ data.text}}</div>
                  </template>

                  <template #ordinal-to-month="{ data }">
                    <div>{{ fromOrdinalToMonth(data.value) }}</div>
                  </template>

                </DxDataGrid>
              </template>

            </basic-chart-datagrid-visualizer>
          </div>
        </template>
      </DxItem>

      <!-- 3st column -->
<!--      <DxItem>-->
<!--        <DxLocation :row="1" :col="4" :colspan="2" screen="lg" />-->
<!--        <DxLocation :row="2" :col="0" :colspan="3" screen="xs sm md" />-->
<!--        <template #default>-->
<!--          <basic-text-visualizer-->
<!--              :componentTitle="LOC.NETSALES.AVGTICKET"-->
<!--              :comparisonDate="comparisonDateText"-->
<!--              :comparisonValue="cloumnStatistic.avegTicket.avgTicketREFPY"-->
<!--              :refDate="refDateText"-->
<!--              :refValue="cloumnStatistic.avegTicket.avgTicketREF"-->
<!--              :trendValue="cloumnStatistic.avegTicket.varAvgTicket"-->
<!--              :isCurrency="true"-->
<!--          />-->
<!--        </template>-->
<!--      </DxItem>-->

      <!--   footer   -->
<!--      <DxItem>-->
<!--        <DxLocation :row="5" :col="3" :colspan="3" screen="lg" />-->
<!--        <DxLocation :row="6" :col="3" :colspan="3" screen="xs sm md" />-->
<!--        <template #default>-->
<!--          <div class="app-version">v. {{ getStoredAppVersion.versionNumber }} - {{ getStoredAppVersion.versionDate }}</div>-->
<!--        </template>-->
<!--      </DxItem>-->

    </DxResponsiveBox>
  </div>
</template>

<script>
import itMessages from 'devextreme/localization/messages/it.json';
import { locale, loadMessages } from "devextreme/localization";

import DxSelectBox from 'devextreme-vue/select-box';
import 'devextreme/dist/css/dx.light.css';
import ApiCalls from "@/services/ApiCalls";

import { mapGetters } from "vuex";
import DxCalendar from 'devextreme-vue/calendar';

import { loc } from "@/config/languages/en.js";

import {
  DxResponsiveBox,
  DxItem,
  DxLocation,
  DxCol,
  DxRow,
} from "devextreme-vue/responsive-box";

/******************************************************************************************************
 * # COMPONENTE - INIZIO
 **/

//import { DxBarGauge } from 'devextreme-vue/bar-gauge';


import DxDataGrid, {
  // DxLoadPanel,
  DxPaging, DxColumn, DxFormat, DxExport } from 'devextreme-vue/data-grid';


import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';

import {
  DxChart,
  DxCommonSeriesSettings,
  DxSeries,
  DxPoint,
  DxLegend,
  DxTooltip,
  DxValueAxis,
  DxArgumentAxis,
  DxGrid,
  DxMinorGrid,
  DxCommonPaneSettings,
  DxBorder,
  DxZoomAndPan,
  DxPane,
  //DxSize,

} from "devextreme-vue/chart";

import {
  // DxFormat,
  //DxLabel,
  // DxConnector,
  //DxExport
} from 'devextreme-vue/pie-chart';

import DxButton from 'devextreme-vue/button';


//import DiffCell from '@/components/datagrid/DiffCell.vue';

/**
 * # /COMPONENTE - FINE
 ******************************************************************************************************/


export default {
  components: {
    DxResponsiveBox,
    DxItem,
    DxLocation,
    DxCol,
    DxRow,
    DxSelectBox,
    DxButton,
    DxCalendar,

    /******************************************************************************************************
     * # COMPONENTE - INIZIO
     **/
    DxDataGrid,
    // DxLoadPanel,
    DxPaging,
    DxExport,


    DxChart,
    DxSeries,
    DxPoint,
    DxCommonSeriesSettings,
    DxLegend,
    DxTooltip,
    DxValueAxis,
    DxArgumentAxis,
    DxGrid,
    DxMinorGrid,
    DxCommonPaneSettings,
    DxBorder,
    //DxSize,
    DxColumn,
    DxFormat,
    DxPane,


    // DxPieChart,
    // DxFormat,
    //DxLabel,
    // DxConnector,
    //DxExport,

    //DxBarGauge,
    DxZoomAndPan,

    //DiffCell, // componente per template personalizzato celle datagrid

    /**
     * # /COMPONENTE - FINE
     ******************************************************************************************************/
  },

  data() {
    /***************
     * # PROVVISORIO: ARRIVERà DA VUEX
     ***************/
        // let outlet = this.$store.getters.getSelectedOutlet.id ? this.$store.getters.getSelectedOutlet.id : 1; // #1
        // let refDate = {year: 2021, month: 10};
    let selectedProductCategory = 0;
    // console.log("er outlet from store: ", outlet)

    return {
      /***************
       * # PROVVISORIO: ARRIVERà DA VUEX
       ***************/
      // parametri per composizione chiamate - prov (arriveranno da vuex, impostato da subheader/partials/drop-down component)
      // outletId: outlet, /* #globale */
      // refDate: refDate, /* #globale */

      /******************************************************************************************************
       * # COMPONENTE - INIZIO
       **/

      /** ## DATE PER I COMPONENTI **********/
      refDateText: "", // arriva da chiamata refDate.plain
      comparisonDateText: "", // arriva da chiamata comparisonDate.plain
      refDateTextLTM: "",
      comparisonDateTextLTM: "",
      refDateTextLTMComparisonDateTextLTM: "",
      refDateTextYTD: "",
      comparisonDateTextYTD: "",

      selectedProductCategory: selectedProductCategory, // #globale

      /** ## SELETTORE CATEGORIE MERCEOLOGICHE **********/
      categories: [], // todo: globale o componente? ogni quanto si ripopolerà? verrà preso da localstorage dopo prima chiamata?

      // DATAGRID CATEGORIES COMPONENT
      // selectedCategory: null, // forse non più utile... #check
      /**
       * # /COMPONENTE - FINE
       ******************************************************************************************************/

      /***************
       * # GENERALI E GLOBALI
       ***************/
      effortRateData: null, /************* # COMPONENTE *********************************/
      // dataFilteredByProductCategory: [], // #componente

      // flag per abilitare eventuali pulsanti solo quando i dati sono stati ricevuti
      pageDataReady: false, /************* # COMPONENTE *********************************/

      // flag per abilitare filtro categorie merceologiche (dipende da pagina - domain) #n
      productCategoryFilterDisabledByPage: null,

      /** ## LOCALIZZAZIONE **********/
      locale: "it",

      LOC: loc, // labels

      /** ## BREAKPOINTS **********/
      screenByWidth: function (width) {
        if (width < 768) return "xs";
        if (width < 1024) return "sm";
        if (width < 1150) return "md";
        // if (width < 1150) return "md";
        return "lg";
      },

      //Settings
      settingsZoomLevel: "decade",
      settingsMaxSelectableDate: null,
      settingsMinSelectableDate: null,
      settingsHidden: true,

      /** ## CALENDAR **********/
      calendarZoomLevel: "year", /* #componente: impostata di default, dipende da pagina: usata per cambiare stato active */
      maxSelectableDate: null,
      minSelectableDate: null, // #n
      calendarHidden: true,

      /******************************************************************************************************
       * # COMPONENTE - INIZIO
       **/

      /** ## BAR GAUGE **********/
      format: { /* #globale */
        type: 'fixedPoint',
        precision: 1
      },

      /** ## DATAGRID **********/
      customizeDatagridDataDecimals(value) { /* #globale */
        // console.log("custom data col: ", value)
        return value.valueText;
        // return value.valueText + " €";
      },

      customizeDatagridDataPercentage(value) { /* #globale */
        return value.valueText;
        // return value.valueText + " %";
      },

      customizeDatagridDataCurrency(value) { /* #globale */
        return "€ " + value.valueText.toLocaleString("it-IT");
      },

      // datagrid loader chiamata filtrata via api: ancora utile?
      loadingVisible: true, /* #componente */

      /***************
       * # COMPONENT ER LTM DISTRIBUTION - BAR GAUGE
       ***************/
      // todo: trasformare in oggetto ogni componente (solo var componente)?

      cloumnStatistic: {
        dataFilteredByProductCategory: [],
        dataFilteredByProductCategoryYTD: [],
        netSales: {
          salesREFPY: 0,
          salesREF: 0,
          varSales: 0
        },
        netSalesSqm: {
          salesSqmREFPY: 0,
          salesSqmREF: 0,
          varSalesSqm: 0
        },
        netSalesYTD: {
          salesPY: 0,
          sales: 0,
          varSales: 0
        },
        netSalesSqmYTD: {
          salesSqmPY: 0,
          salesSqm: 0,
          varSalesSqm: 0
        },
        avegTicket: {
          avgTicketREFPY: 0,
          avgTicketREF: 0,
          varAvgTicket: 0
        },
        spendPerVisitor: {
          spvREFPY: 0,
          spvREF: 0,
          varSPV: 0
        },
        openDays: {
          openDaysREFPY: 0,
          openDaysREF: 0
        },
        crRetail: {
          crREFPY: 0,
          crREF: 0,
          varCR: 0
        }
      },

      barGaugeER: { // #componente
        dataRef: [],
        dataComparison: [],
        legend: {},
      },

      barGaugeGeometry: { /* #globale */
        endAngle:-30,
        startAngle:210
      },
      colorGood: "#59C47F", /* #globale */
      colorNormal: "#FFD341", /* #globale */
      colorBad: "#FF5633", /* #globale */
      colorBadLight: "#F8B5BF", /* #globale */
      barGaugeLabelFont: {family: 'Nunito, sans-serif', size: 18, weight: 400}, /* #globale */
      // todo: label font-size a 15 in 768 e 1024

      /***************
       * # COMPONENT TEXT VISITORS
       ***************/
      textVisitors: {
        dataFilteredByProductCategory: {},
      },


      /***************
       * # COMPONENT ER LTM
       ***************/
      scatterDatagridERLTM: {
        // componentTitle: "Effort Rate LTM %",
        dataFilteredByProductCategory: null,
      },

      /** ## SCATTER PLOT **********/
      dataDisplayMode1: 'chart', /* #componente: serve a togliere il reset zoom button in seguito ad azione su switch (risolve problema su ipad con chrome) */
      dataDisplayMode2: 'chart', /* #componente: serve a togliere il reset zoom button in seguito ad azione su switch (risolve problema su ipad con chrome) */

      effortRateDisplayMode: "chart", /* #componente */

      scatterErInstance1: null, /* #componente */
      scatterErInstance2: null, /* #componente */

      scatterChartCommonAxisSettings: { /* #globale */
        //todo: color non viene applicato: scoprire cosa lo sovrascrive
        label:
            { font:
                  { size: 14, family: 'Nunito', weight: 400, color: '#787878' }
            },
        title:
            { margin: 14 ,
              font:
                  { size: 14, family: 'Nunito', weight: 400, color: '#666666' }
            }
      },

      scatterERLTM: {
        // argumentAxis: {
        //   title: { text: 'Net Sales (€)'},
        // },
        // valueAxis: {
        //   title: { text: 'E.R. (%)'},
        // },
        series1: {
          color: "#FF5633",
        },
        series2: {
          color: "#F8B5BF",
        },
        series3: {
          color: "#85ff33",
        },
        points1: {
          color: "#FF7373",
          size: 15,
        },
        points2: {
          color: "#F8B5BF",
          size: 15,
        },
        points3: {
          color: "#85ff33",
          size: 15,
        },
        tooltipVisitors: {
          argumentFormat: { type: 'fixedPoint' },
          valueFormat: { type: 'fixedPoint' },
        },
        tooltipCr: {
          argumentFormat: { type: 'fixedPoint' },
          valueFormat: { type: 'decimal', precision: 2 },
        },
      },

      tooltipTemplateFormat: null,
      tooltipTemplateArgumentFormat: null,

      /***************
       * # COMPONENT WORST PERFORMER - DATAGRID
       ***************/
      datagridERWorstPerformer: {
        // componentTitle: "Worst Performers",
        dataFilteredByProductCategory: null,
      },

      /***************
       * # COMPONENT BEST PERFORMER - DATAGRID
       ***************/
      datagridERBestPerformer: {
        // componentTitle: "Best Performers",
        dataFilteredByProductCategory: null,
      },

      /**
       * # /COMPONENTE - FINE
       ******************************************************************************************************/

    };
  },

  computed: {
    ...mapGetters(["getSelectedOutlet", "getOutletsList", "getCategoriesList", "getOutletLastSalesDates", "getNewOutletLastSalesDates", "getOutletStyle", "getStoredAppVersion", "getLegendFontSettings"]),

    /***************
     * # CALENDAR
     ***************/
    displayStringDate() { /* #globale */
      if(this.$store.state.selectedDate) {
        let year = this.$store.state.selectedDate.slice(0, 4);
        let month = this.fromOrdinalToMonth(Number((this.$store.state.selectedDate.slice(3, 5) - 1)))

        return `${month} ${year}`;
      } else {
        let year = this.$store.state.newOutletLastSalesDates.lastAvailableSalesDate.slice(0, 4);
        let month = this.fromOrdinalToMonth(Number((this.$store.state.newOutletLastSalesDates.lastAvailableSalesDate.slice(3, 5) - 1)))

        return `${month} ${year}`;
      }
    },
  },

  methods: {
    changeDisplayData1(value) { /* #componente: serve a togliere il reset zoom button in seguito ad azione su switch (risolve problema su ipad con chrome) */
      this.dataDisplayMode1 = value;
    },

    changeDisplayData2(value) { /* #componente: serve a togliere il reset zoom button in seguito ad azione su switch (risolve problema su ipad con chrome) */
      this.dataDisplayMode2 = value;
    },

    getTooltipTemplate(e) {
      return (e.point.series.pane == "bottomPane") ? "tooltipTemplateNetSale" : "tooltipTemplateNetSaleSqm";
    },

    getTooltipTemplate2(e) {
      return (e.point.series.pane == "bottomPane") ? "tooltipTemplateAVG" : "tooltipTemplateSPV";
    },

    customizeLabel() {
      return {
        visible: true,
        backgroundColor: 'rgba(255,255,255,0)',
        color: '#000000',
        customizeText({ valueText }) {
          return `${valueText}`;
        },
      };
    },

    /***************
     * # CALENDAR
     ***************/

    // funzione per convertire numeri da una a due cifre
    convertFormatDate(date) {
      console.log("date: ", date);
      return String(date).length < 2 ? ("0" + date) : date;
    },

    onSelectedDate(e) {
      // console.log("selectedDate: ", new Date(e.value));
      let temp = new Date(e.value);
      // console.log("temp: ", temp);
      let selectedDate = `${temp.getFullYear()}${this.convertFormatDate(Number(temp.getMonth())+1)}${this.convertFormatDate(temp.getDate())}`
      // console.log("selectedDate: ", selectedDate);

      // imposto data selezionata in vuex
      this.$store.dispatch("dispatchSelectedDate", selectedDate);
      console.log("selectedDate - vuex: ", this.$store.state.selectedDate);

      // chiamata api per riprendere dati pagina er (tenendo conto di categoria selezionata)
      this.getEffortRateData();

      // nascondi il calendario
      this.calendarHidden = true;

    },

    onSelectedSetting(e) {

      let temp = new Date(e.value);
      let selectedSetting = `${temp.getFullYear()}`;
      this.$store.dispatch("dispatchSelectedSetting", selectedSetting);

      this.getEffortRateData();
      this.settingsHidden = true;
    },

    // funzione per impostare il range di date selezionabili (month, week)
    onCalendarZoomLevelSelection(calendarZoomLevelSelected) {
      // impostazione zoom level calendario
      this.calendarZoomLevel = calendarZoomLevelSelected.value;
      console.log("calendar zoom level: ", this.calendarZoomLevel);

      // imposta ultima data selezionabile
      this.setMaxSelectableDate()
    },

    // imposta ultima data selezionabile
    setMaxSelectableDate() {
      if(this.calendarZoomLevel === "year") {
        console.log("newOutletLastSalesDates", this.$store.state.newOutletLastSalesDates);
        let tempLastDateYear = (this.$store.state.newOutletLastSalesDates.lastAvailableSalesDate + "").slice(0, 4);
        let tempLastDateMonth = (this.$store.state.newOutletLastSalesDates.lastAvailableSalesDate + "").slice(4, 6);

        // ricavo data di partenza in formato data
        let tempLastDate = new Date(tempLastDateYear, tempLastDateMonth, 0);

        // ricreo la data in formato devextreme e la imposto nel calendario
        this.maxSelectableDate = `${tempLastDate.getFullYear()}${(tempLastDate.getMonth() + 1).toLocaleString(undefined, {minimumIntegerDigits: 2})}${tempLastDate.getDate()}`; // #n

        // console.log("calendar max selectable date: ", this.maxSelectableDate);
        // console.log("calendar max selectable date - old: ", this.$store.state.outletLastSalesDates.salesLastDayClosedMonth);  // old

        //  imposto min data selezionabile su calendario // #n
        console.log("prova start first date: ", this.$store.state.newOutletLastSalesDates.firstAvailableSalesDate);
        let tempFirstDateYear = (this.$store.state.newOutletLastSalesDates.firstAvailableSalesDate + "").slice(0, 4);
        let tempFirstDateMonth = (this.$store.state.newOutletLastSalesDates.firstAvailableSalesDate + "").slice(4, 6);

        // ricavo data di partenza in formato data
        let tempFirstDate = new Date(tempFirstDateYear, tempFirstDateMonth, 1);
        console.log("temp first date: ", tempFirstDate);

        // ricreo la data in formato devextreme e la imposto nel calendario
        this.minSelectableDate = `${tempFirstDate.getFullYear()}${(tempFirstDate.getMonth()).toLocaleString(undefined, {minimumIntegerDigits: 2})}${tempFirstDate.getDate().toLocaleString(undefined, {minimumIntegerDigits: 2})}`; // #n

        this.settingsMaxSelectableDate = `${tempLastDate.getFullYear()-1}${(tempLastDate.getMonth() + 1).toLocaleString(undefined, {minimumIntegerDigits: 2})}${tempLastDate.getDate()}`; // #n;
        this.settingsMinSelectableDate = "2018";
      }

      // if(this.calendarZoomLevel === "month") { // per ora non utilizzato !? attenzione: da aggiornare con lastAvailableSalesDate !? // #n
      //   this.maxSelectableDate = this.$store.state.outletLastSalesDates.salesLastDayClosedWeek;
      // }

      console.log("max selectable data: ", this.maxSelectableDate);
      console.log("calendar min selectable date: ", this.minSelectableDate);
    },

    onShowCalendar() {
      this.calendarHidden === true ? this.calendarHidden = false : this.calendarHidden = true;
    },

    onShowSettings() {
      this.settingsHidden === true ? this.settingsHidden = false : this.settingsHidden = true;
    },

    // getCellCssClass(date) {
    //   let cssClass = '';
    //   const holydays = [[1, 0], [4, 6], [25, 11]];
    //
    //   if (this.isWeekend(date)) { cssClass = 'weekend'; }
    //
    //   holydays.forEach((item) => {
    //     if (date.getDate() === item[0] && date.getMonth() === item[1]) {
    //       cssClass = 'holyday';
    //     }
    //   });
    //
    //   return cssClass;
    // },

    /***************
     * # DATAGRID CHART
     ***************/
    /** ## CONVERTE GLI ORDINALI IN MESI (NETSALES, ...) **********/
    fromOrdinalToMonth(ordinalNumber) { /* #globale */
      // console.log("fn fromOrdinalToMonth called");

      if(typeof ordinalNumber != "number") {
        ordinalNumber = Number(ordinalNumber)
      }

      let output = ordinalNumber;
      switch(ordinalNumber) {
        case -11:
          output = "Jan";
          break;
        case -10:
          output = "Feb";
          break;
        case -9:
          output = "Mar";
          break;
        case -8:
          output = "Apr";
          break;
        case -7:
          output = "May";
          break;
        case -6:
          output = "Jun";
          break;
        case -5:
          output = "Jul";
          break;
        case -4:
          output = "Aug";
          break;
        case -3:
          output = "Sep";
          break;
        case -2:
          output = "Oct";
          break;
        case -1:
          output = "Nov";
          break;
        case 0:
          output = "Dec";
          break;
        case 1:
          output = "Jan";
          break;
        case 2:
          output = "Feb";
          break;
        case 3:
          output = "Mar";
          break;
        case 4:
          output = "Apr";
          break;
        case 5:
          output = "May";
          break;
        case 6:
          output = "Jun";
          break;
        case 7:
          output = "Jul";
          break;
        case 8:
          output = "Aug";
          break;
        case 9:
          output = "Sep";
          break;
        case 10:
          output = "Oct";
          break;
        case 11:
          output = "Nov";
          break;
        case 12:
          output = "Dec";
          break;
        default:
          output = "-";
      }

      return output;
    },

    /** ## VALORE VISUALIZZATO IN ARGUMENT AXIS **********/
    newArgAxisValue(value) {
      return this.fromOrdinalToMonth(value.value);
    },

    /***************
     * # GENERALE
     ***************/
    onDrawn(e) { /* #globale */
      setTimeout(function () {
        e.component.render();
      }, 1)
    },

    /** ## FILTRO OUTLETS - parte 1: impostazione filtro e chiamate api **********/
    onOutletSelected(e) {
      console.log("selezionato outlet: ", e.value);

      // condizione per pulsante clear: onclick passa null -> defaultOutlet
      let selectedOutletId = e.value == null ? 1 : e.value;

      // ricavo l'outlet dall'id selezionato (in vuex selectedOutlet è l'obj completo)
      let selectedOutlet = this.$store.state.outletsList.filter(outlet => outlet.id == selectedOutletId)[0];

      // invio l'outlet selezionato a vuex
      this.$store.dispatch("dispatchSelectedOutlet", selectedOutlet)

      console.log("selected outlet - vuex: ", this.$store.state.selectedOutlet);

      // al cambio di outlet, reimposto le categorie a 0 (all): non tutti gli outlets hanno le stesse categorie
      this.$store.dispatch("dispatchSelectedCategory", 0);
      // reimpostare anche selectbox categorie
      console.log("on outlet change, reset selected category - vuex: ", this.$store.state.selectedCategory);

      // recupero le categorie dell'outlet e le imposto nel vuex
      this.getDataByCategories();

      // reimposto le ultime date selezionabili in calendario
      this.setMaxSelectableDate();
    },

    /** ## CHIAMATA API FILTRO OUTLETS (anche chiamata iniziale pagina) **********/
    getEffortRateData() {
      console.log("getEffortRateData called");

      // recupero variabili per chiamata da store
      let outletId = this.$store.state.selectedOutlet.id;
      // console.log("api call - outletId: ", outletId);

      let outletsDatesYear = "";
      let outletsDatesMonth = "";

      let yearComp = "";

      // controllo che selected date non sia impostata: se == null, cambiato selezione outlet o primo caricamento pagina: perciò prendo ultima data da outlet
      if(this.$store.state.selectedDate == null) {
        //#n
        outletsDatesYear = (this.$store.state.newOutletLastSalesDates.lastAvailableSalesDate + "").slice(0, 4); // nel caso arrivi un numero, trasformo in stringa // #n
        console.log("api call - outletsDatesYear", outletsDatesYear);
        // outletsDatesMonth = 10; // temp per test: non ci sono tutti i dati per l'ultimo mese fornito dalle api  // todo: ricordati di passare alla modalità non test
        outletsDatesMonth = (this.$store.state.newOutletLastSalesDates.lastAvailableSalesDate + "").slice(4, 6); // #n
        console.log("api call - outletsDatesMonth", outletsDatesMonth); // /#n
      } else {
        outletsDatesYear = this.$store.state.selectedDate.slice(0, 4);
        console.log("api call - outletsDatesYear", outletsDatesYear);
        // outletsDatesMonth = 10;
        outletsDatesMonth = this.$store.state.selectedDate.slice(4, 6);
        console.log("api call - outletsDatesMonth", outletsDatesMonth);
      }

      if(this.$store.state.selectedSettings == null) {

        yearComp = outletsDatesYear-1;

      } else {

        yearComp = this.$store.state.selectedSettings;
      }

      ApiCalls.getNetSales(outletId, outletsDatesYear, outletsDatesMonth, yearComp)
          .then(res => {
            console.log("chiamata api er res: ", res.data);

            // #n
            // imposta dominio in vuex per decidere quali filtri lasciare attivi
            let currentDomain = res.data.metadata.domain;
            this.$store.dispatch("dispatchCurrentDomain", currentDomain);
            // console.log("vuex domain: ", this.$store.state.currentDomain);

            // flag per disabilitare filtri a seconda della pagina
            if(this.$store.state.pagesDisabledFilters.productCategoryFilter.some(domain => domain == currentDomain)) {
              this.productCategoryFilterDisabledByPage = true;

              // se filtro disabilitato, visualizza tutte le categorie merceologiche
              this.$store.dispatch("dispatchSelectedCategory", 0);
            } else {
              this.productCategoryFilterDisabledByPage = false;
            }
            // console.log("productCategoryFilterDisabledByPage: ", this.productCategoryFilterDisabledByPage);
            // /#n

            this.effortRateData = res.data;

            this.setComponentsData();
          })
          .catch(err => console.log(err.response));
    },


    /** ## CHIAMATA LOCALE FILTRO CLIENT - CATEGORIE **********/
    onProductCategorySelected(e) {

      // imposto selectedCategory in vuex
      console.log("selezionato categoria: ", e.value);
      e.value == null ?
          this.$store.dispatch("dispatchSelectedCategory", 0):
          this.$store.dispatch("dispatchSelectedCategory", e.value);

      console.log("selected category - vuex: ", this.$store.state.selectedCategory);

      // rivalorizzo i dataset dei vari componenti
      this.setComponentsData();
    },

    /** ## VALORIZZAZIONE DATASET DEI VARI COMPONENTI **********/
    setComponentsData() {

      let result = this.effortRateData.result.salesSnapshot;
      let resultYTD = this.effortRateData.result.salesTotalsYTD
      let metadata = this.effortRateData.metadata;
      let selectedProductCategory = this.$store.state.selectedCategory;

      //proprietà che verrà usata per filtrare i tenants a seconda delle categorie di prodotto: se categoria negativa, fitrerò su food / non food, altrimenti su categoria prodotto
      let targetProperty = selectedProductCategory < 0 ? "prodCatClusterId" : "prodCatId";

      //DATA FOR TEXT VISITORS
      //this.textVisitors.dataFilteredByProductCategory = result.footfallTotals.filter(el => el[targetProperty] === selectedProductCategory)[0];

      //Dates
      metadata.periods[0] && metadata.periods[0].type === "REF" ? this.refDateText = metadata.periods[0].plain : this.refDateText = "not specified";
      metadata.periodsComparison[0] && metadata.periodsComparison[0].type === "REF" ? this.comparisonDateText = metadata.periodsComparison[0].plain : this.comparisonDateText = "not specified";

      metadata.periods[1] && metadata.periods[1].type === "LTM" ? this.refDateTextLTM = metadata.periods[1].plainAlt : this.refDateTextLTM = "not specified";
      metadata.periodsComparison[1] && metadata.periodsComparison[1].type === "LTM" ? this.comparisonDateTextLTM = metadata.periodsComparison[1].plainAlt : this.comparisonDateTextLTM = "not specified";

      // nuovo: da_canc se non necessario
      metadata.periods[2] && metadata.periods[2].type === "YTD" ? this.refDateTextYTD = metadata.periods[2].plainAlt : this.refDateTextYTD = "not specified";
      metadata.periodsComparison[2] && metadata.periodsComparison[2].type === "YTD" ? this.comparisonDateTextYTD = metadata.periodsComparison[2].plainAlt : this.comparisonDateTextYTD = "not specified";
      // /

      this.refDateTextLTMComparisonDateTextLTM = this.refDateTextLTM + " " + this.LOC.NETSALES.PY + " " + this.comparisonDateTextLTM + ")";


      this.cloumnStatistic.dataFilteredByProductCategory = result.filter(el => el[targetProperty] === selectedProductCategory)[0];
      this.cloumnStatistic.dataFilteredByProductCategoryYTD = resultYTD.filter(el => el[targetProperty] === selectedProductCategory)[0];

      //NetSales
      this.cloumnStatistic.netSales.salesREFPY = this.cloumnStatistic.dataFilteredByProductCategory.salesREFPY ?? 0;
      this.cloumnStatistic.netSales.salesREF = this.cloumnStatistic.dataFilteredByProductCategory.salesREF ?? 0;
      this.cloumnStatistic.netSales.varSales = this.cloumnStatistic.dataFilteredByProductCategory.varSales ?? 0;

      //NetSalesSqm
      this.cloumnStatistic.netSalesSqm.salesSqmREFPY = this.cloumnStatistic.dataFilteredByProductCategory.salesSqmREFPY ?? 0;
      this.cloumnStatistic.netSalesSqm.salesSqmREF = this.cloumnStatistic.dataFilteredByProductCategory.salesSqmREF ?? 0;
      this.cloumnStatistic.netSalesSqm.varSalesSqm = this.cloumnStatistic.dataFilteredByProductCategory.varSalesSqm ?? 0;

      //NetSalesYTD
      this.cloumnStatistic.netSalesYTD.salesPY = this.cloumnStatistic.dataFilteredByProductCategoryYTD.salesPY ?? 0;
      this.cloumnStatistic.netSalesYTD.sales = this.cloumnStatistic.dataFilteredByProductCategoryYTD.sales ?? 0;
      this.cloumnStatistic.netSalesYTD.varSales = this.cloumnStatistic.dataFilteredByProductCategoryYTD.varSales ?? 0;

      //NetSalesSqmYTD
      this.cloumnStatistic.netSalesSqmYTD.salesSqmPY = this.cloumnStatistic.dataFilteredByProductCategoryYTD.salesSqmPY ?? 0;
      this.cloumnStatistic.netSalesSqmYTD.salesSqm = this.cloumnStatistic.dataFilteredByProductCategoryYTD.salesSqm ?? 0;
      this.cloumnStatistic.netSalesSqmYTD.varSalesSqm = this.cloumnStatistic.dataFilteredByProductCategoryYTD.varSalesSqm ?? 0;

      //AvegTicket
      this.cloumnStatistic.avegTicket.avgTicketREFPY = this.cloumnStatistic.dataFilteredByProductCategory.avgTicketREFPY ?? 0;
      this.cloumnStatistic.avegTicket.avgTicketREF = this.cloumnStatistic.dataFilteredByProductCategory.avgTicketREF ?? 0;
      this.cloumnStatistic.avegTicket.varAvgTicket = this.cloumnStatistic.dataFilteredByProductCategory.varAvgTicket ?? 0;

      //SpendPerVisitor
      this.cloumnStatistic.spendPerVisitor.spvREFPY = this.cloumnStatistic.dataFilteredByProductCategory.spvREFPY ?? 0;
      this.cloumnStatistic.spendPerVisitor.spvREF = this.cloumnStatistic.dataFilteredByProductCategory.spvREF ?? 0;
      this.cloumnStatistic.spendPerVisitor.varSPV = this.cloumnStatistic.dataFilteredByProductCategory.varSPV ?? 0;

      //OpenDays
      this.cloumnStatistic.openDays.openDaysREFPY = this.cloumnStatistic.dataFilteredByProductCategory.openDaysREFPY ?? 0;
      this.cloumnStatistic.openDays.openDaysREF = this.cloumnStatistic.dataFilteredByProductCategory.openDaysREF ?? 0;
      //this.cloumnStatistic.spendPerVisitor.varSPV = this.cloumnStatistic.dataFilteredByProductCategory.varSPV ?? 0;

      //CrRetail
      this.cloumnStatistic.crRetail.crREFPY = this.cloumnStatistic.dataFilteredByProductCategory.crREFPY ?? 0;
      this.cloumnStatistic.crRetail.crREF = this.cloumnStatistic.dataFilteredByProductCategory.crREF ?? 0;
      this.cloumnStatistic.crRetail.varCR = this.cloumnStatistic.dataFilteredByProductCategory.varCR ?? 0;

      console.log("selectedProductCategory: ", selectedProductCategory);

      //DATA FOR LINE CHART E DATAGRID
      this.scatterDatagridERLTM.dataFilteredByProductCategory = this.effortRateData.result.salesDetailsLTM.filter(el => el[targetProperty] === selectedProductCategory)[0].items;
      console.log("scatterDatagridERLTM.dataFilteredByProductCategory all: ", this.scatterDatagridERLTM.dataFilteredByProductCategory);

      this.pageDataReady = true; /* #componente */
    },

    /******************************************************************************************************
     * # COMPONENTE - INIZIO
     **/

    /***************
     * # BAR GAUGE
     ***************/
    customizeText({valueText}) {
      return `${valueText} %`;
    },

    /***************
     * # DATAGRID
     ***************/
    /** ## EXPORT BUTTON **********/
    onExportingDatagridEr(e) { /* #globale */
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Main sheet');
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        customizeCell: function (options) {
          const excelCell = options;
          excelCell.font = {name: 'Arial', size: 12};
          excelCell.alignment = {horizontal: 'left'};
        }
      }).then(function () {
        workbook.xlsx.writeBuffer()
            .then(function (buffer) {
              saveAs(new Blob([buffer], {type: 'application/octet-stream'}), 'DataGrid.xlsx');
            });
      });
      e.cancel = true;
    },

    /***************
     * # SCATTER
     ***************/
    /** ## RESET ZOOM BUTTON **********/
    saveScatterErInstance(e) {
      this.scatterErInstance1 = e.component;
    },

    resetScatterErZoom1() {
      console.log(this.scatterErInstance1)
      this.scatterErInstance1.resetVisualRange()
      // this.scatterErInstance.ActualAxisY.ActualVisualRange.SetAuto();
    },

    saveScatterErInstance2(e) {
      this.scatterErInstance2 = e.component;
    },

    resetScatterErZoom2() {
      console.log(this.scatterErInstance2)
      this.scatterErInstance2.resetVisualRange()
      // this.scatterErInstance.ActualAxisY.ActualVisualRange.SetAuto();
    },

    periodOrdinalCalculate(rowData) {
      return (rowData.periodOrdinal > 0) ? rowData.periodPlain : rowData.periodPlainPY;
    },

    /** ## TOOLTIP **********/

    //  DATAGRID WORST EFFORT RATE
    //   sortDataWorstER(data) {
    //     return data.sort((a,b) => b.effortRatePercentLTM - a.effortRatePercentLTM);
    //   },
  },

  /**
   * # /COMPONENTE - FINE
   ******************************************************************************************************/

  beforeCreate() {
    // this.outletId = this.$store.getters.getSelectedOutlet.id; // #1
  },

  created() {

    loadMessages(itMessages);
    locale(this.locale);

    // #todo: adottare sintassi con params xé + chiara?

    console.log("vuex: ", this.$store);

    /***************
     * # 0: main.js
     ***************/

    this.getOutletsData();


  },



};

// todo: chiedere perché componenti scoped prevengono alcune regole css (es: css-class in dxcolumn
</script>

<style lang="scss" scoped>
/***************
* # FILTRI
***************/
//
//.filterContainer { /* #globale */
//  padding: 10px;
//  display: flex;
//  justify-content: space-between;
//  flex-direction: row !important;
//  align-items: center;
//
//  //h2 {
//  //  font-family: Nunito, sans-serif;
//  //  font-size: 26px;
//  //  font-weight: 600;
//  //
//  //  @media screen and (max-width: 1024px){
//  //    font-size: 22px;
//  //  }
//  //}
//
//  //#categoriesListProv {
//  //  text-align: center;
//  //  padding: 7px;
//  //  border: none;
//  //  border-radius: 14px;
//  //  appearance: none;
//  //
//  //  color: #AA192F;
//  //  background-color: #FDE5E8;
//  //  //width: 117px;
//  //  //height: 24px;
//  //}
//
//}

/** ## CALENDAR **********/


/***************
* # BAR GAUGE
***************/
.gauge {
  height: 232px;
}

/***************
* # SCATTER - DATAGRID
***************/
.scatterChart, .datagrid {
  height: 413px;
}

.linesPanes {
  height: 600px;
}


/***************
* # COMPONENT WORST PERFORMER - DATAGRID
***************/

/*** ***** #!! ATTENZIONE: alcuni elementi css non vengono presi xé il componente è scoped
 -> passarli in custom-style.css !! */

//.tenant-column {
//  color: orange !important;
//  font-size: 50px !important;
//  background-color: green !important;
//}
//
//
//.cell-highlighted {
//  background-color: green !important;
//  color: orange !important;
//  font-style: italic !important;
//  font-size: 25px !important;
//}


// boh? #check
.logos-container {
  margin: 20px 0 40px 0;
  text-align: center;
  svg {
    display: inline-block;
  }
}

.devextreme-logo {
  width: 200px;
  height: 34px;
  margin-bottom: 17px;
}

.vue-logo {
  width: 180px;
  height: 62px;
}

.plus {
  margin: 20px 10px;
  width: 22px;
  height: 22px;
}

.screen-x-small .logos-container {
  svg {
    width: 100%;
    display: block;
    &.plus {
      margin: 0;
    }
  }
}
// /boh? #check


@media screen and (max-width: 1024px) {
  .gauge { // #check: da mettere in custom-style? Pensaci dopo aver fatto tutte le pagine.
    height: 175px;
  }

  .scatterChart, .datagrid { // #check: da mettere in custom-style? Pensaci dopo aver fatto tutte le pagine.
    height: 313px;
  }
}
@media screen and (max-width: 768px) {


}
</style>
