<template>
<!--    <router-link :to="{ name: 'tenant-page', params: { tenant: cellData.text }}" class="tenant-name">-->
      <div class="tenant-name" style="height: 100%; padding-top: 5px">
        <span v-if="cellData.data.hasOwnProperty('erCat')" class="er-indicator" :style="`background-color: ${cellData.data.erCat == 'GOOD' ? this.getErColors.colorGood : cellData.data.erCat == 'NORMAL' ? this.getErColors.colorNormal : cellData.data.erCat == 'BAD' ? this.getErColors.colorBad : 'transparent'}`" ></span>
        <span>
          {{ cellData.text }}
        </span>
      </div>
<!--    </router-link>-->
</template>
<script>
// import {
//   DxSparkline,
//   DxSize,
//   DxTooltip,
// } from 'devextreme-vue/sparkline';


import {mapGetters} from "vuex";

export default {
  // components: {
  //   DxSparkline,
  //   DxSize,
  //   DxTooltip,
  // },
  props: {
    cellData: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    ...mapGetters(["getErColors"])
  },

  created() {
    console.log("DiffCell: ", this.cellData)
  }

};
</script>
<style lang="scss" scoped>
  a:hover {
    text-decoration: none !important;
  }

  .tenant-name {
    color: inherit;
  }

  .er-indicator {
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 5px;
  }

</style>
