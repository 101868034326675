// vue crypto js

import axios from "axios";
import ApiCalls from "@/services/ApiCalls";
import router from "@/router";
const { baseURLRoot } = require('../custom-config.js')

const defaultUser = !window.localStorage.loggedUser ? null : { email: JSON.parse(window.localStorage.getItem('loggedUser'))?.username };

//     {
//   // username: !window.localStorage.loggedUser ? 'null' : window.localStorage.getItem('loggedUser'),
//   email: !window.localStorage.loggedUser ? 'null' : window.localStorage.getItem('loggedUser').username,
//   // username: "oijoi",
//   // avatarUrl: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/06.png'
// };

const q = require("querystring");

export default {
  _user: defaultUser,
  loggedIn() {
    console.debug("this_user logged in", this._user)
    return !!this._user;
  },

  async logIn(email, password) {

    return axios({
      method: "post",
      url: baseURLRoot + "/login",
      data: q.stringify({
        grant_type: 'password',
        username: email,
        password: password,
        //username: 'Test Dashboard',
        //password: 'TestDashboard.01'
      }),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    })
    .then(res => {

      window.localStorage.setItem("accessToken", res?.data?.access_token);
      window.localStorage.setItem("refreshToken", res?.data?.refresh_token);
      window.localStorage.setItem("refreshTokenExpiringDate", res?.data?.[".expires"]);

      const loggedUser = { username: email };

      window.localStorage.setItem("loggedUser", JSON.stringify(loggedUser))
      this._user = {...defaultUser, email};
      console.debug("_user: ", this._user);

      return {
        isOk: true,
        data: this._user
      }

    })
    .catch(err => {
      console.log("axios err: ", err.response.data.error);

      return {
        isOk: false,
        data: err?.response?.data?.error
      };
    })
  },

  async logOut() {
    await ApiCalls.delLogOut();
    window.localStorage.clear();
    this._user = null;
    router.push({
      path: "/login-form",
      query: { redirect: router.path }
    });
  },

  async getUser() {
    try {
      // Send request
      console.log("user", this.data)

      return {
        isOk: true,
        data: this._user
      };
    }
    catch {
      return {
        isOk: false
      };
    }
  },

  async resetPassword(email) {
    try {
      // Send request
      console.log(email);

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to reset password"
      };
    }
  },

  async changePassword(email, recoveryCode) {
    try {
      // Send request
      console.log(email, recoveryCode);

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to change password"
      }
    }
  },

  async createAccount(email, password) {
    try {
      // Send request
      console.log(email, password);

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to create account"
      };
    }
  }
};
